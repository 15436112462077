import revive_payload_client_jJJm1KuYBC from "/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.0_ioredis@5_aqlfl7l5ns6cm3bel3jqauvz4m/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_8rcfHZqMfE from "/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.0_ioredis@5_aqlfl7l5ns6cm3bel3jqauvz4m/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_DoEqmjidpH from "/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.0_ioredis@5_aqlfl7l5ns6cm3bel3jqauvz4m/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_I0oWz7vVQu from "/app/node_modules/.pnpm/nuxt-site-config@2.2.12_@nuxt+devtools@1.3.7_rollup@4.17.2_vite@5.3.2_@types+node@22.10.1_ter_ln2unh2kltkcat57ofqvh35nzm/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_A0ZMF0nhzk from "/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.0_ioredis@5_aqlfl7l5ns6cm3bel3jqauvz4m/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_27KBVFnqiD from "/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.0_ioredis@5_aqlfl7l5ns6cm3bel3jqauvz4m/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_zZ2a1vyWPB from "/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.0_ioredis@5_aqlfl7l5ns6cm3bel3jqauvz4m/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_qP5Ic1FE9u from "/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.0_ioredis@5_aqlfl7l5ns6cm3bel3jqauvz4m/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import error_handler_client_sQhJOBiqXf from "/app/plugins/error-handler.client.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
export default [
  revive_payload_client_jJJm1KuYBC,
  unhead_8rcfHZqMfE,
  router_DoEqmjidpH,
  _0_siteConfig_I0oWz7vVQu,
  payload_client_A0ZMF0nhzk,
  check_outdated_build_client_27KBVFnqiD,
  components_plugin_KR1HBZs4kY,
  prefetch_client_zZ2a1vyWPB,
  chunk_reload_client_qP5Ic1FE9u,
  error_handler_client_sQhJOBiqXf,
  sentry_client_shVUlIjFLk
]